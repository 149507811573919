//
// IE
//
// Import this file using the following HTML or equivalent:
// <!--[if IE]>
//     <link href="dist/css/ie.min.css" media="all" rel="stylesheet" type="text/css" />
// <![endif]-->
//
.header {
	nav {
		ul {
			li {
				&.home {
					a {
						span {
							background-size: auto auto;
							background-image: url(../../images/home-icoon-01hvr.png);
						}
					}
					&.current-menu-item, &.current-page-ancestor {
						a {
							span {
								background-size: auto auto;
								background-image: url(../../images/home-icoon-01.png);
							}
						}
					}
				}
				&.care {
					a {
						span {
							background-size: auto auto;
							background-image: url(../../images/zorgaanbod-icoonhvr.png);
						}
					}
					&.current-menu-item, &.current-page-ancestor {
						a {
							span {
								background-size: auto auto;
								background-image: url(../../images/zorgaanbod-icoon.png);
							}
						}
					}
				}
				&.activitie {
					a {
						span {
							background-size: auto auto;
							background-image: url(../../images/activiteiten-icoonhvr.png);
						}
					}
					&.current-menu-item, &.current-page-ancestor {
						a {
							span {
								background-size: auto auto;
								background-image: url(../../images/activiteiten-icoon.png);
							}
						}
					}
				}
				&.pennemes {
					a {
						span {
							background-size: auto auto;
							background-image: url(../../images/pennemes-icoonhvr.png);
						}
					}
					&.current-menu-item, &.current-page-ancestor {
						a {
							span {
								background-size: auto auto;
								background-image: url(../../images/pennemes-icoon.png);
							}
						}
					}
				}
				&.ask {
					a {
						span {
							background-size: auto auto;
							background-image: url(../../images/vragen-icoon-01hvr.png);
						}
					}
					&.current-menu-item, &.current-page-ancestor {
						a {
							span {
								background-size: auto auto;
								background-image: url(../../images/vragen-icoon-01.png);
							}
						}
					}
				}
			}
		}
	}
}